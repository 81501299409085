.fix-mui-align * {
  line-height: inherit;
}

*{
	margin: 0;
	padding: 0;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	/* line-height: normal; */
	/*transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;*/
}

a {
  text-decoration: none;
  color: var(--skyBlue);
}

a:link {
  /* color: var(--skyBlue); */
}

.errorText {
	color: var(--kRed);
}

.progress {
	line-height: normal;
}