.dash-content {
  font-family: Noto Sans, 'Helvetica Neue', sans-serif !important;
  height: calc(100% - 72px);
  padding: 5px 5px 5px 5px;
  color: var(--darkBlack);
}

.list-head-tabs {
  width: 100%;
  margin: auto;
}

.tabs {
  /*margin-right: auto;*/
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}
.tabs button {
  /* min-width: 10em; */
}

.dash-body {
  /* background-color: white; */
  height: 100%;
  width: 100%;
  margin: auto;
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.dash-body > * {
  padding: 3px 8px;
}

.dash-caseload,
.dash-org {
  max-height: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 10px;
}

.dash-org {
  overflow-y: auto;
}

.dash-counts {
  flex-basis: 100%;
  max-width: 1200px;
  width: 100%;
  height: 78px;
  display: flex;
}

.dash-count {
  /* display: inline-block; */
  width: 24%;
  margin: 0 0.5%;
  background-color: white;
  height: 100%;
  border-radius: 4px;
  background-color: var(--steelBlue);
  position: relative;
  color: white;
}
.dash-count svg {
  height: 60px !important;
  width: 60px !important;
  fill: white !important;
  margin: 7px 0 0 5px;
}

.dash-count div,
.dash-count label {
  position: absolute;
  right: 10px;
  text-align: right;
}

.dash-count label {
  top: 50px;
  font-size: 12px;
}

.dash-count div {
  top: 0px;
  font-size: 40px;
}

.dash-col,
.dash-schedule-col {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 49%;
  margin: 0 0.5%;
}

.dash-schedule-col {
  max-width: 400px;
  width: 32%;
}

.dash-col-chart {
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 49%;
  margin: 0 0.5%;
}

.dash-chart {
  background-color: white;
  /* overflow: hidden; */
  /* display: flex; */
  /* flex-direction: column; */
  /* max-width: 600px; */
  /* width: 49%; */
  margin: 7px 0;
}
.x-axis-group > .tick > text {
  transform: scale(0.75) translate(3%, 3%) rotate(45deg);
}

.y-axis-group {
  display: none;
  width: 0;
}

.percentage-label {
  transform: translate(2.5%);
}

@media screen and (max-width: 599px) {
  /* for phone only */
  .content-body {
    display: block;
  }
  .dash-caseload,
  .dash-org {
    /* flex-direction: column; */
    display: block;
    overflow-x: hidden;
  }
  .dash-col,
  .dash-col-chart,
  .dash-schedule-col {
    width: 100%;
  }
  .header-title {
    top: -3px;
  }
  .dash-counts {
    display: initial;
    margin-bottom: 5px;
    flex-basis: auto;
  }
  .dash-count {
    width: 100%;
    display: block;
    margin-bottom: 5px;
    height: 78px;
  }
}

@media screen and (max-width: 899px) {
  /* for tablet portrait */
}

@media screen and (max-width: 1199px) {
  /* for tablet landscape */
}
