@import '../../styles/common.css';

.app-root {
  background-color: var(--skyBlue);
}

.app-root-printpreview {
  background-color: white;
}

.app-main {
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  /* height: calc(100% - 65px); */
  position: relative;
}

.app-main *:not(.ms-Icon) {
  font-family: Noto Sans, 'Helvetica Neue', sans-serif !important;
}

.app-main.drawer-open {
  margin-left: 256px;
}

.header-title * {
  /* line-height: 50px; */
  vertical-align: middle;
}

.header-logo {
  height: 40px;
  /*width: 40px;*/
  margin-right: 10px;
}

.error-dialog {
  padding-top: 10px;
}

.missing-text {
  padding-top: 3px;
}

.mobile-indicator {
  position: fixed;
  margin: 8px auto; /* Will not center vertically and won't work in IE6/7. */
  left: 0;
  right: 0;
}

body {
  height: 100%;
  /*overflow: hidden;*/
  overflow-x: hidden;
}

#root > div > div:nth-child(3) {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/var(--paleBlue)+1,aae0fa+90,e3e42c+90,e3e42c+100 */
  background: rgba(170, 224, 250, 1); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    var(--paleBlue) 0%,
    var(--paleBlue) 98%,
    var(--paleLimeGreen) 98%,
    var(--paleLimeGreen) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    var(--paleBlue) 0%,
    var(--paleBlue) 98%,
    var(--paleLimeGreen) 98%,
    var(--paleLimeGreen) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    var(--paleBlue) 0%,
    var(--paleBlue) 98%,
    var(--paleLimeGreen) 98%,
    var(--paleLimeGreen) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='var(--paleBlue)', endColorstr='var(--paleLimeGreen)',GradientType=0 ); /* IE6-9 */
  height: calc(100vh - 56px);
  z-index: 1;
}

#root > div > div:nth-child(3)::after {
  content: '';
  position: absolute;
  display: block;
  top: calc(100% - 185px);
  left: 10px;
  right: 0;
  bottom: 0;
  /* height: 260px; */
  width: 100%;
  background: url('../../heartwater.svg');
  background-repeat: no-repeat;
  z-index: -1;
}

.container {
  height: 100%;
}

.list-head {
  /* background-color: white; */
  height: 53px;
  width: 100%;
  margin: auto;
  overflow-y: scroll;
}

.list-search {
  margin-bottom: 3px;
  max-width: 900px;
  margin: 0 auto;
  background-color: white;
}

.list-section {
  min-height: 72px;
  margin-bottom: 3px;
  max-width: 900px;
  margin: 0 auto;
  background-color: white;
}

.header-icons {
  top: -4px;
}

.person-right-button {
  position: relative;
  top: -12px;
  right: 12px;
}

.person-card {
  background-color: white !important;
  position: relative;
  overflow: hidden !important;
  /* min-height: 62px; */
}

.person-card:not(:last-of-type) {
  border-bottom: 1px var(--kGreyLight) solid !important;
}

.person-card-back {
  position: absolute;
  right: -10px;
  top: -40px;
  height: 150px !important;
  width: 150px !important;
  opacity: 0.15;
  overflow: hidden;
  transform: rotate(10deg);
}

.content {
  font-family: Noto Sans, 'Helvetica Neue', sans-serif !important;
  height: calc(100%);
  padding: 5px 5px 5px 5px;
  color: var(--darkBlack);
}

.app-header {
  height: 56px;
}

.app-header > * {
  top: -4px;
}

.header-title {
  top: -19px;
  position: relative;
  /* font-weight: bold; */
  font-family: 'DIN 1451', Noto Sans, 'Helvetica Neue', sans-serif !important;
  color: var(--limeGreen);
  cursor: pointer;
  letter-spacing: 2pt;
}

.header-title-only {
  top: -3px;
  position: relative;
  /* font-weight: bold; */
  font-family: 'DIN 1451', Noto Sans, 'Helvetica Neue', sans-serif !important;
  color: var(--limeGreen);
  cursor: pointer;
  letter-spacing: 2pt;
}

.content-title {
  background-color: white;
  display: none;
}

.content-body {
  /* background-color: white; */
  height: 98%;
  width: 100%;
  margin: auto;
  overflow-y: scroll;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.fab {
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 10;
}

.speed-dial {
  right: 14px !important;
  left: auto !important;
  bottom: 13px !important;
}

.bubble-list {
  right: 2px !important;
}

.bubble-list > * {
  font-family: Roboto, sans-serif !important;
}

.list-body {
  /* background-color: white; */
  height: 100%;
  height: 87.05%;
  width: 100%;
  margin: auto;
  overflow-y: scroll;
}

.content-body > * {
  padding: 3px 8px;
}

.content-column {
  overflow: visible;
  display: flex;
  flex-direction: column;
  max-width: 600px;
  width: 50%;
}

.content-box {
  background-color: white;
  margin: 0 0 10px 0;
}

.content-box > * {
  padding: 3px 8px;
}

.content-box > * > .person-card:last-child,
.attachment:last-child {
  margin-bottom: 10px;
}

.file-icon {
  font-size: 24px;
  height: 24px;
  width: 24px;
  margin-right: 10px;
  margin-left: 5px;
  vertical-align: middle;
}

.infinite-progress {
  margin: 5px auto;
  display: block !important;
}

.disabled-link {
  pointer-events: none;
}

@media screen and (max-width: 599px) {
  /* for phone only */
  .content-body {
    display: block;
  }
  .content-column,
  .delete-box {
    width: 100%;
  }
  .header-title {
    top: -3px;
  }
}

@media screen and (max-width: 899px) {
  /* for tablet portrait */
}

@media screen and (max-width: 1199px) {
  /* for tablet landscape */
}

@media screen and (max-width: 1799px) {
  /* for desktop */
}
