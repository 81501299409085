.content-body {
  overflow-y: auto;
}

.printPreview {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 25px;
  margin-left: 25px;
}

.printPreviewHeader {
  width: 100%;
  page-break-inside: auto;
  font-size: 12px;
}

.printPreviewHeader tbody tr td {
  padding-left: 2px;
  font-size: 16px;
  font-weight: bold;
  /* border: 1px solid rgb(147, 149, 152); */
}

table {
  margin-bottom: 10px;
}
table tbody tr td {
  word-wrap: break-word;
}

table.printPreviewHeader tr:nth-child(1) td:nth-child(1){
  text-align: left;
}

table.printPreviewHeader tr:nth-child(1) td:nth-child(2){
  text-align: left;
}

table.printPreviewHeader tr:nth-child(1) td:nth-child(3){
  text-align: right;
}

.printPreviewTable {
  width: 100%;
  table-layout: fixed;
  page-break-inside: auto;
  font-size: 12px;
  border-collapse: collapse;
}

.printPreviewTable tbody tr td {
  padding-left: 8px;
  /* border: 1px solid rgb(147, 149, 152); */
}

.printPreviewTableHeaderRow {
  height: 32px;
  color: white;
  background-color: rgb(147, 149, 152);
}

.printPreviewTableBodyRow {
  height: 26px;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.fieldTitle {
  font-weight: bold;
  background-color: #d3d3d38c;
}

.fieldValue {
  /* font-weight: bold; */
}

/*Household*/
table.householdDetails .fieldTitle {
  width: 230px;
}

table.householdDetails tr:nth-child(5) td:nth-child(1){
  /* vertical-align: text-top; */
}

table.carerDetails .fieldTitle {
  min-width: 230px;
}

table.otherHouseholdMembers .fieldTitle {
  min-width: 230px;
}

table.staffDetails .fieldTitle {
  min-width: 230px;
}

table.placementDetails .fieldTitle {
  min-width: 230px;
}

table.meetingDetails .fieldTitle {
  min-width: 230px;
}

table.accommodationDetails .fieldTitle {
  min-width: 230px;
}
/*Household*/

/*YoungPerson*/
table.ypContactDetails .fieldTitle {
  width: 230px;
}

table.ypPersonalDetails .fieldTitle {
  width: 230px;
}

table.ypEducationDetails .fieldTitle {
  width: 230px;
}

table.ypMedicalDetails .fieldTitle {
  width: 230px;
}

table.ypCareDetails .fieldTitle {
  width: 230px;
}

table.ypParentGuardians .fieldTitle {
  min-width: 230px;
}

table.ypSiblings .fieldTitle {
  min-width: 230px;
}

table.ypReferrals .fieldTitle {
  min-width: 230px;
}

table.ypCases .fieldTitle {
  min-width: 230px;
}

table.ypViewsAndWishes .fieldTitle {
  min-width: 230px;
}
/*YoungPerson*/

@media print {
  body {
    overflow: visible;
  }

  .printPreviewTableHeaderRow {
    /* page-break-after: avoid;
    page-break-before: avoid; */
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }

  .printPreviewTableBodyRow {
    /* page-break-after: avoid;
    page-break-before: avoid; */
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }
}
