.contact-card {
  padding: 8px 0;
  position: relative;
  overflow: hidden;
}

.contact-card-back {
  position: absolute;
  right: -100px;
  bottom: -67px;
  height: 350px !important;
  width: 350px !important;
  opacity: .15;
  transform: rotate(10deg);
}

.contact-card-menu {
  float: right;
  top: -8px;
  position: relative;
}

.contact-card .contact-card-menu {
  visibility: hidden;
}

.contact-card .contact-card-menu-visible {
  visibility: visible;
}

.contact-card:hover .contact-card-menu {
  visibility: visible;
}

.contact-card-read-only {
  font-size: 10pt;
}

.contact-card-address-read-only {
  display: inline-block;
  width: 50%;
}
