.referral-card {
  position: relative;
  overflow: hidden;
}

.referral-card:not(:last-of-type) {
  border-bottom: 1px var(--kGreyLight) solid !important;
}

.referral-card-back {
  position: absolute;
  right: -10px;
  bottom: -40px;
  height: 150px !important;
  width: 150px !important;
  opacity: .15;
  transform: rotate(10deg);
}
