.dropZone,
.dropZoneActive {
  background-color: var(--lightSkyBlue)
}

.dropZone:active,
.dropZoneActive {
  background-color: var(--skyBlue);
}

.dropZonedDisabled {
  background-color: white;
}

.attachment {
  background-color: var(--paleBlue);
}

.attachment {
  padding: 5px;
}
