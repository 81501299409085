.clamped-dialog {
  padding: 0 !important;
}

.clamped-dialog > div > div {
  transform: translate(0, 10px) !important;
}

.clamped-dialog-title {
  box-shadow: 0 1px 8px 0 rgba(0,0,0,.2), 0 3px 4px 0 rgba(0,0,0,.14), 0 3px 3px -2px rgba(0,0,0,.12);
  margin-bottom: 10px !important;
}

.clamped-dialog-close {
  float: right;
  margin-top: -10px !important;
  margin-right: -10px !important;
}

.clamped-dialog-mobile > div > div {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;
  transform: translate(0, -67px) !important;
}

.clamped-dialog-mobile > div > div > div > :nth-child(2) {
  max-height: calc(100vh - 88px - 52px) !important;
  overflow-y: auto !important;
}
