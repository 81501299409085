.printPDF {
  width: 100%;
}

.printPDF {
  width: 100%;
}

.printPDFHeader {
  width: 100%;
  page-break-inside: auto;
  font-size: 12px;
}

.printPDFHeader tbody tr td {
  padding-left: 2px;
  font-size: 12px;
  font-weight: bold;
  /* border: 1px solid rgb(147, 149, 152); */
}

table.printPDFHeader tr:nth-child(1) td:nth-child(1){
  text-align: left;
}

table.printPDFHeader tr:nth-child(1) td:nth-child(2){
  text-align: left;
}

table.printPDFHeader tr:nth-child(1) td:nth-child(3){
  text-align: right;
}

.printPDFTable {
  width: 100%;
  table-layout: fixed;
  page-break-inside: auto;
  font-size: 10px;
  border-collapse: collapse;
}

.printPDFTable tbody tr td {
  padding-left: 4px;
  /* border: 1px solid rgb(147, 149, 152); */
}

.printPDFTableHeaderRow {
  height: 20px;
  color: white;
  background-color: rgb(147, 149, 152);
}

.printPDFTableBodyRow {
  height: 18px;
  border-bottom: 1px solid rgb(224, 224, 224);
}

@media print {
  body {
    overflow: visible;
  }

  .printPDFTableHeaderRow {
    /* page-break-after: avoid;
    page-break-before: avoid; */
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }

  .printPDFTableBodyRow {
    /* page-break-after: avoid;
    page-break-before: avoid; */
    page-break-inside: avoid;
    -webkit-print-color-adjust: exact;
  }
}
