
.drawer {
  color: rgba(0, 0, 0, 0.87);
}

.drawer *:not(.ms-Icon) {
  font-family: Noto Sans, 'Helvetica Neue', sans-serif !important;
}

.drawer .drawer-content {
  height: calc(100% - 56px) !important;
  margin-top: 56px;
  overflow-x: hidden !important;
  /* background: none !important; */
}

.drawer .profile-pic {
  display: block !important;
  margin: 10px auto;
}

.drawer .nav-active > div {
  background-color: var(--steelBlue);
  /* background-color: var(--kGreyLight); */
}

.drawer-list-item {
  color: white !important;
}

.drawer-list-sub-item {
  padding-left: 24px !important;
}

.drawer-list-item:hover {
  background-color: var(--steelBlue) !important;
}

.office-logo {
  font-size: 24px;
  height: 24px;
  width: 24px;
  margin: 0 25px;
  top: -12px !important;
}
