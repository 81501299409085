.keywordsListItem {
  display: flex;
  align-items: center;
}

.keywordsListItem > :nth-child(2) {
  width: calc(100% - 24px);
  display: inline-block;
}

.arrows {
  vertical-align: middle;
  width: 24px;
  display: inline-block;
  height: 58px;
}

.arrows > svg {
  display: none !important;
  width: 24px;
  height: 24px;
}

.arrows > svg:not(.svgDisabled):hover {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.1);
}

.arrows > svg:not(.svgDisabled):active {
  color: #007dc5 !important;
  background-color: rgba(0, 0, 0, 0.2);
}

.keywordsListItem:hover .arrows > svg {
  display: inline-block !important;
}

.iconDanger {
  fill: rgb(231, 76, 60) !important;
}

.floating {
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
}

.keyword-form {
  margin-bottom: 3px;
  max-width: 900px;
  margin: 10px auto;
  background-color: white;
}

.keyword-form > * {
  max-width: 98%;
  margin-left: 1%;
}

.body {
  padding: 0px !important;
  width: 100%;
  max-width: 500px;
}

.smallList {
  overflow-y: auto;
  max-height: calc(100% - 186px);
}

.smallList > * {
  background-color: rgba(255, 255, 255, 0.8);
}

@media screen and (max-width: 1024px), screen and (max-height: 500px) {
  .keywordsListItem > .arrows > svg {
    display: inline-block !important;
  }
  .body {
    overflow-y: auto;
    padding: 10px;
  }
  .smallList {
    max-height: calc(100% - 185px);
  }
}

@media screen and (max-height: 500px) {
  .smallList {
    max-height: calc(100% - 113px);
  }
}
