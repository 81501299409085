:root {
  --limeGreen: rgba(227,228,44,1);
  --skyBlue: rgba(37,170,225,1);
  --lightSkyBlue: rgba(60, 199, 244, 1);
  --steelBlue: rgba(30,136,180,1);
  --kRed: rgba(231,76,60,1);
  --paleBlue: rgba(170,224,250,1);
  --paleLimeGreen: rgba(245,243,160,1);
  --kGrey: rgba(147,149,152,1);
  --kGreyLight: rgba(147,149,152,0.25);
  --darkBlack: rgba(62,62,63,1);
  --excelLightGreen: rgba(146,208,80,1);
  --excelOrange: rgba(255,192,0,1);
  --excelGreen: rgba(0,176,80,1);
  --excelBlue: rgba(91,155,213,1);
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
::-webkit-scrollbar-button:start:decrment, ::-webkit-scrollbar-button:end:increment {
  background: transparent;
  display: none;
}
::-webkit-scrollbar-track-piece {
  background: var(--kGreyLight);
}
::-webkit-scrollbar-track-piece:vertical:start {
  border-radius: 5px 5px 0 0;
}
::-webkit-scrollbar-track-piece:vertical:end {
  border-radius: 0 0 5px 5px;
}
::-webkit-scrollbar-track-piece:horizontal:start {
  border-radius: 5px 0 0 5px;
}
::-webkit-scrollbar-track-piece:horizontal:end {
  border-radius: 0 5px 5px 0;
}
::-webkit-scrollbar-thumb:vertical, ::-webkit-scrollbar-thumb:horizontal {
  background: var(--kGrey);
  border-radius: 5px;
  display: block;
  height: 50px;
}
/* ::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #999;
  border: 37px none #ffffff;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #7f7f7f;
}
::-webkit-scrollbar-thumb:active {
  background: #666666;
}
::-webkit-scrollbar-track {
  background: #989898;
  border: 0px none #ffffff;
  border-radius: 50px;
}
::-webkit-scrollbar-track:hover {
  background: #b1b1b1;
}
::-webkit-scrollbar-track:active {
  background: #989898;
}
::-webkit-scrollbar-corner {
  background: transparent;
} */

/*
Styles for material-ui Tabs.
*/
