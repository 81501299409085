.log,
.log-column-names {
  font-size: 10pt;
  display: flex;
  align-items: stretch;
  margin-bottom: 5px;
  margin-top: 10px;
  border-bottom: 1px #dedede solid;
}

a.log:visited {
  color: black;
}

a.log {
  color: black;
}

.log-column-names {
  font-weight: bold;
  position: sticky;
  top: 0px;
  background-color: white;
}

.log-section-small {
  width: 24%;
  margin-right: 1%;
}

.log-section-big {
  width: 49%;
  margin-right: 1%;
}

.log-section-small-all {
  width: 19%;
  margin-right: 1%;
}

.log-section-big-all {
  width: 39%;
  margin-right: 1%;
}

.log-list-section {
  padding: 5px;
  min-height: 72px;
  margin-bottom: 3px;
  max-width: 900px;
  margin: 0 auto;
  background-color: white;
}

.log-list-body {
  height: 100%;
  width: 100%;
  margin: auto;
  overflow-y: scroll;
}
